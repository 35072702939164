import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CreationMode } from 'src/app/core/definitions/enums';

@Component({
    selector: 'app-node-actions-menu',
    templateUrl: './node-actions-menu.component.html',
    styleUrls: ['./node-actions-menu.component.scss']
})
export class NodeActionsMenuComponent {
    @Input() editionModeEnabled = true;
    @Output() deleteNode = new EventEmitter();
    @Output() toggleEditionMode = new EventEmitter();
    @Output() creationModeChange = new EventEmitter<CreationMode>();

    creationMode = CreationMode.CreateNew;
    creationModeCreateNew = CreationMode.CreateNew;
    creationModeFindExisting = CreationMode.FindExisting;

    onEditionModeToggle(event: Event): void {
        event.stopImmediatePropagation(); // this prevents closing the node
        this.toggleEditionMode.emit();
    }

    onDelete(event?: Event): void {
        event?.stopImmediatePropagation(); // this prevents closing the node
        this.deleteNode.emit();
    }

    onChangeCreationNode(creationMode: CreationMode) {
        this.creationMode = creationMode;
        this.creationModeChange.emit(this.creationMode);
    }

    get creationModeIcon() {
        return this.creationMode === this.creationModeCreateNew
            ? 'create-new'
            : 'find-existing';
    }
}
