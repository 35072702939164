import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-custom-icon-menu',
    templateUrl: './custom-icon-menu.component.html',
    styleUrls: ['./custom-icon-menu.component.scss']
})
export class CustomIconMenuComponent {
    @Input() disabled: boolean;
    @Input() opened: boolean;

    @Output() toggle = new EventEmitter();

    constructor() {
        this.disabled = false;
        this.opened = false;
    }

    onToggle(event?: Event): void {
        event?.stopImmediatePropagation();
        if (this.disabled) {
            return;
        }
        this.opened = !this.opened;
        this.toggle.emit(this.opened);
    }
}
