import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

const ICONS = [
    { iconName: 'arrow-contract', iconPath: 'arrow-contract.svg' },
    { iconName: 'bin', iconPath: 'bin.svg' },
    { iconName: 'boolean', iconPath: 'properties/boolean.svg' },
    { iconName: 'date', iconPath: 'properties/date.svg' },
    { iconName: 'edit-round', iconPath: 'edit-round.svg' },
    { iconName: 'edit', iconPath: 'edit.svg' },
    { iconName: 'elements', iconPath: 'elements.svg' },
    { iconName: 'help', iconPath: 'help.svg' },
    { iconName: 'import-file', iconPath: 'import-file.svg' },
    { iconName: 'link', iconPath: 'link.svg' },
    { iconName: 'list', iconPath: 'list.svg' },
    { iconName: 'logout', iconPath: 'logout.svg' },
    { iconName: 'menu', iconPath: 'menu.svg' },
    { iconName: 'number', iconPath: 'properties/number.svg' },
    { iconName: 'number-gray', iconPath: 'properties/number-gray.svg' },
    { iconName: 'organization', iconPath: 'organization.svg' },
    { iconName: 'patterns', iconPath: 'patterns.svg' },
    { iconName: 'person-outline', iconPath: 'person-outline.svg' },
    { iconName: 'separator', iconPath: 'separator.svg' },
    { iconName: 'sortAZ', iconPath: 'sortAZ.svg' },
    { iconName: 'sortZA', iconPath: 'sortZA.svg' },
    { iconName: 'text', iconPath: 'properties/string.svg' },
    { iconName: 'tip_black', iconPath: 'tip_black.svg' },
    { iconName: 'tip', iconPath: 'tip.svg' },
    { iconName: 'tree', iconPath: 'tree.svg' },
    { iconName: 'resend_invitation', iconPath: 'resend-invitation.svg' },
    { iconName: 'vector', iconPath: 'vector.svg' },
    { iconName: 'edit-round', iconPath: 'edit-round.svg' },
    { iconName: 'separator', iconPath: 'separator.svg' },
    { iconName: 'element-tree', iconPath: 'properties/tree.svg' },
    { iconName: 'element-tree-gray', iconPath: 'properties/tree-gray.svg' },
    { iconName: 'key', iconPath: 'key.svg' },
    { iconName: 'money-sign', iconPath: 'money-sign.svg' },
    { iconName: 'separator', iconPath: 'separator.svg' },
    { iconName: 'error-icon', iconPath: 'error-icon.svg' },
    { iconName: 'x-error', iconPath: 'x-error.svg' },
    { iconName: 'element-warning', iconPath: 'element-warning.svg' },
    { iconName: 'field-error', iconPath: 'field-error.svg' },
    { iconName: 'computer', iconPath: 'computer.svg' },
    { iconName: 'double-arrow-up', iconPath: 'double-arrow-up.svg' },
    { iconName: 'outline-warning', iconPath: 'outline-warning.svg' },
    { iconName: 'computer', iconPath: 'computer.svg' },
    { iconName: 'calendar', iconPath: 'calendar.svg' },
    { iconName: 'drive_file_rename_outline', iconPath: 'drive_file_rename_outline.svg' },
    { iconName: 'click-on-reference', iconPath: 'click-on-reference.svg' },
    { iconName: 'double-arrow-up', iconPath: 'double-arrow-up.svg' },
    { iconName: 'settings-outline', iconPath: 'settings.svg' },
    { iconName: 'sandbox', iconPath: 'sandbox.svg' },
    { iconName: 'check-circle', iconPath: 'check-circle.svg' },
    { iconName: 'inventory', iconPath: 'inventory.svg' },
    { iconName: 'create-new', iconPath: 'create-new.svg' },
    { iconName: 'find-existing', iconPath: 'find-existing.svg' },
    { iconName: 'alternate-email', iconPath: 'alternate-email.svg' },
    { iconName: 'info', iconPath: 'info.svg' },
    { iconName: 'views', iconPath: 'view.svg' },
    { iconName: 'eye-slash', iconPath: 'eye-slash.svg' }
];

@NgModule({
    imports: [HttpClientModule]
})
export class MatIconRegistryModule {
    constructor(
        private domSanitizer: DomSanitizer,
        private matIconRegistry: MatIconRegistry
    ) {
        this.registerIcons();
    }

    private registerIcons(): void {
        ICONS.forEach(({ iconName, iconPath }) => {
            const iconUrl = this.generateIconUrl(iconPath);
            this.matIconRegistry.addSvgIcon(iconName, iconUrl);
        });
    }

    private generateIconUrl(iconPath: string): SafeResourceUrl {
        return this.domSanitizer.bypassSecurityTrustResourceUrl(
            `/assets/icons/${iconPath}`
        );
    }
}
