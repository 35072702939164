<article>
    <app-custom-icon-menu class="menu-button" (toggle)="delete.hideConfirmation()">
        <app-custom-icon-menu-confirm-button #delete id="delete" (action)="onDelete()">
        </app-custom-icon-menu-confirm-button>

        <button
            [class.hidden]="!editionModeEnabled"
            data-test-id="editButton"
            (click)="onEditionModeToggle($event)"
            (mousedown)="$event.stopImmediatePropagation()"
        >
            <mat-icon id="edit">edit</mat-icon>
        </button>
        <!-- <button [matMenuTriggerFor]="menu" (click)="$event.stopPropagation()">
            <mat-icon [svgIcon]="creationModeIcon"></mat-icon>
        </button> -->
    </app-custom-icon-menu>
</article>
<mat-menu #menu="matMenu" yPosition="above">
    <div class="workroad-menu">
        <div mat-menu-item (click)="onChangeCreationNode(creationModeCreateNew)">
            Create new
            <mat-icon svgIcon="create-new"></mat-icon>
        </div>
        <div mat-menu-item (click)="onChangeCreationNode(creationModeFindExisting)">
            Find existing
            <mat-icon svgIcon="find-existing"></mat-icon>
        </div>
    </div>
</mat-menu>
